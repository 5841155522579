import { WithinFilterDto } from '@/lib/shared/dto/within-filter.dto';
import { LatLng } from '@/models/LatLng';
import { SEARCH_ROUTES } from '@/utils/constants';
import { decodeQueryParamAsString } from '@/utils/stringify-url';

import { Bounds, CameraMapOptions } from '../map/types';
import { SearchType } from './search-type.enum';
import { SearchQueryParams } from './useSearchPageParams';

export const CURRENT_LOCATION_VALUE = 'CurrentLocation';

export function getSearchValueFromQuery(query?: string | null) {
  if (query) {
    if (!query || query === CURRENT_LOCATION_VALUE) return undefined;
    return decodeURIComponent(query);
  }
  return undefined;
}

export function getLatLngFromQuery(
  queryParams: SearchQueryParams
): LatLng | undefined {
  const centerParam = queryParams.center;
  if (!centerParam) return undefined;

  const [longitude, latitude] = decodeQueryParamAsString(centerParam)
    .split(',')
    .map((coord) => +coord);

  if (isNaN(longitude) || isNaN(latitude)) return undefined;

  return {
    lat: latitude,
    lng: longitude,
  };
}

export function getRoute(searchType: SearchType) {
  if (searchType === SearchType.MeetingRoom) {
    return SEARCH_ROUTES.MEETING_ROOMS;
  }
  if (searchType === SearchType.Coworking) {
    return SEARCH_ROUTES.COWORKING;
  }
  return SEARCH_ROUTES.LOCATIONS;
}

export function createLocalizationQueryParams(
  coords?: CameraMapOptions,
  query?: string
) {
  if (!coords) return { query };

  const coordsBounds = coords.bounds as [number, number, number, number];
  const coordsCenter = coords.center as [number, number];

  return {
    query,
    bounds: coordsBounds ? coordsBounds.join(',') : undefined,
    center: coordsCenter.join(','),
    zoom: coords.zoom.toString(),
  };
}

export function mapBounds(bounds: Bounds): WithinFilterDto {
  return {
    northEastPoint: {
      longitude: bounds.ne.lng,
      latitude: bounds.ne.lat,
    },
    southWestPoint: {
      longitude: bounds.sw.lng,
      latitude: bounds.sw.lat,
    },
  };
}
