import { useMemo } from 'react';

import useQueryParams from '@/hooks/useQueryParams';
import { usePathname } from '@/navigation';

import { SearchType } from './search-type.enum';
import { SearchViewMode } from './types';

export type SearchQueryParams = {
  view?: SearchViewMode;
  day?: string;
  startTime?: string;
  endTime?: string;
  isAllDay?: string;
  amenities?: string;
  seats?: string;
  query?: string;
  center?: string;
  bounds?: string;
  zoom?: string;
  startDay?: string;
  endDay?: string;
};

export default function useSearchPageParams() {
  const pathname = usePathname();
  const { queryParams, updateQuery } = useQueryParams<SearchQueryParams>();

  const searchType = useMemo(() => {
    if (pathname.startsWith('/meeting-rooms')) {
      return SearchType.MeetingRoom;
    }
    if (pathname.startsWith('/locations')) {
      return SearchType.Location;
    }
    return SearchType.Coworking;
  }, [pathname]);

  return {
    searchType,
    queryParams,
    updateQuery,
  };
}
