import { useEffect, useState } from 'react';

export function useWebGL() {
  const [isWebGLSupported, setWebGLsupported] = useState<boolean | undefined>();

  useEffect(() => {
    if (isWebGLSupported !== undefined) return;

    (async () => {
      const mapboxgl = await import('mapbox-gl');
      setWebGLsupported(mapboxgl.supported());
    })();
  }, []);

  return { isWebGLSupported };
}
