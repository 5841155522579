import debounce from 'lodash.debounce';
import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any) => any>(
  func: T,
  ms: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies?: any[]
) {
  return useCallback(debounce(func, ms), dependencies || []);
}
