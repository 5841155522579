'use client';

import { useTranslations } from 'next-intl';

import { getSearchSchema } from '@/lib/schemas/search-schema';
import { usePathname } from '@/navigation';
import { getServerConfig } from '@/utils/config';

import useSearchPageParams from './useSearchPageParams';

export default function SearchSchema() {
  const t = useTranslations('search');
  const pathname = usePathname();
  const { HOST } = getServerConfig();

  const { searchType } = useSearchPageParams();

  const searchSchema = getSearchSchema({
    meta_title: t(`meta.${searchType}.title`),
    meta_description: t(`meta.${searchType}.desc`),
    pathname: `${HOST}${pathname}`,
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(searchSchema) }}
    />
  );
}
