import { WebSite, WithContext } from 'schema-dts';

export function getSearchSchema({
  meta_title,
  meta_description,
  pathname,
}: {
  meta_title: string;
  meta_description: string;
  pathname: string;
}): WithContext<WebSite> {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: meta_title,
    url: pathname,
    description: meta_description,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${pathname}?query={query}&bounds={bounds}&center={center}&zoom={zoom}`,
      // @ts-expect-error - https://github.com/google/schema-dts/issues/114
      'query-input': [
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'query',
          description: 'Location where users want to find a meeting room',
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'bounds',
          description:
            'Geographic bounds for the search area (latitude and longitude values)',
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'center',
          description:
            'Center point of the search area (latitude and longitude)',
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'zoom',
          description: 'Zoom level for the map search (numeric value)',
        },
      ],
    },
  };
}
